.profile {
  height: 80%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;

  .profileItems {
    height: 100%;
    width: 90%;
    line-height: 2;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    h1 {
      font-family: sans-serif;
      color: lightgray;
      margin: 20px 0;
    }

    .container {
      height: 80%;
      width: 90%;
      display: flex;

      .left {
        height: 300px;
        width: 45%;
        display: flex;
        align-items: center;

        img {
          height: 70%;
          width: 70%;
          border-radius: 5%;
          object-fit: cover;
          display: flex;
          align-items: center;
        }
      }

      .right {
        height: 300px;
        width: 65%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .itemKey {
        font-weight: 500;
        font-size: 18px;
      }
      .itemValue {
        font-weight: 300;
        font-family: sans-serif;
        font-size: 16px;
      }
    }
  }
}

button {
  height: 25px;
  width: 50px;
  background-color: #cefad0;
  color: #008631;
  border: 1px solid #008631;
  border-radius: 3px;
}
button:disabled {
  background-color: crimson;
  color: #ece8ff;
}

.editDetail {
  height: 100%;
  width: 55%;

  .itemKeyEdit {
    font-weight: bold;
    color: gray;
    margin-right: 10px;

    &.displayname {
      margin-right: 20px;
    }

    &.username {
      margin-right: 15px;
    }

    &.phone {
      margin-right: 50px;
    }

    &.address {
      margin-right: 32px;
    }

    &.country {
      margin-right: 34px;
    }
  }

  input {
    width: 60%;
    height: 25px;
    outline-color: #6439ff;
    border: 0.5px solid gray;
    color: black;
  }
}

.file {
  display: flex;
  align-items: center;
}
