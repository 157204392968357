.navbar {
  height: 50px;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  position: relative;

  .wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search {
      display: flex;
      align-items: center;
      border: 0.5px solid lightgray;
      padding: 3px;

      input {
        border: none;
        outline: none;
        background: transparent;

        &::placeholder {
          font-size: 12px;
        }
      }
    }

    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;

        .icon {
          font-size: 20px;
        }

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
        }

        .counter {
          width: 15px;
          height: 15px;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }
    }
  }
}

.searchResults {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  min-width: 15%;
  height: max-content;
  //background-color: #ededed;
  background-color: #ffffff;
  z-index: 1;

  .searchBox {
    background-color: white;
    flex-direction: column;
    width: 84.5%;
    height: 100%;
    display: flex;
    align-content: center;
    border: 0.5px solid lightgray;

    p {
      display: flex;
      align-items: center;
      margin: 7px;

      .searchImage {
        //width: 50px;
        //border-radius: 5px;
        margin: 0 5px;
        width: 30px;
        height: 30px;
        //background-size: cover;
        //background-position: 50%;
        border-radius: 50px;
        object-fit: cover;
        //display: table;
        overflow-x: hidden;
        overflow-y: scroll;
      }
    }
  }
}
